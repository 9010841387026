<template>
  <v-container class="d-flex align-start justify-center" fill-height>
    <v-card class="pa-5 login-card">
      <v-form @submit.prevent="submitLogin">

        <!-- Input de usuario -->
        <div>
          <label for="username" style="display: none;">Usuario:</label>
          <input
              id="username"
              v-model="username"
              placeholder="Usuario"
              required
              style="width: 100%; padding: 10px; background-color: #e0e0e0; border: none; border-radius: 0;"
              type="text"
          />
        </div>

        <!-- Input de contraseña -->
        <div style="margin-top: 10px;">
          <label for="password" style="display: none;">Contraseña:</label>
          <input
              id="password"
              v-model="password"
              placeholder="Contraseña"
              required
              style="width: 100%; padding: 10px; background-color: #e0e0e0; border: none; border-radius: 0;"
              type="password"
          />
        </div>

        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

        <!-- Botón de enviar con margen proporcional abajo -->
        <v-card-actions class="d-flex justify-center" style="margin-top: 30px;">
          <v-btn class="v-btn-geen" color="#009496" text-color="white" type="submit">Entrar</v-btn>
        </v-card-actions>

        <!-- Mensajes con separación proporcional -->
        <v-card-actions
            class="text-center justify-center"
            style="margin-top: 10px; max-width: 100%; padding-bottom: 20px;">
          <div class="login-text">
            ¿Olvidó su usuario y/o contraseña?<br>
            Favor de enviar correo<br>
            a sistemas@grupocormac.com<br><br>
            ¿Aún no cuenta con usuario?<br>
            Favor de solicitarlo vía correo a<br>
            sistemas@grupocormac.com
          </div>
        </v-card-actions>


      </v-form>
    </v-card>
  </v-container>
</template>


<script>
import {mapActions, mapState} from 'vuex';



export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['rol']), // Mapea userRole desde Vuex
  },
  methods: {
    ...mapActions(['login']),
    async submitLogin() {
      try {
        await this.login({username: this.username, password: this.password});
        if (this.rol === "cliente") {
          this.$router.push({name: 'ConsultaFoliosView'}); // Cambia a la ruta correcta
        }
      } catch (error) {
        console.error('Error al iniciar sesión:', error);
        this.errorMessage = 'Credenciales incorrectas. Intenta nuevamente.'; // Mensaje de error

      }
    }
  }
};
</script>
<style scoped>
.v-btn-geen {
  margin-bottom: 10px; /* Reduce el margen inferior del botón */

  padding: 15px 30px; /* Ajusta el espaciado interno según sea necesario */
  font-size: 16px;
  margin: 0 10px;
  border-radius: 30px;
  color: white !important;
  background-color: #009496;
  border: none;
  cursor: pointer;

  /* Tamaño fijo del botón */
  width: 200px; /* Ajusta el ancho según sea necesario */
  height: 100px; /* Ajusta la altura según sea necesario */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ajustes para centrar el cuadro de login */
.v-container {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 30vw;
  min-height: 25vh; /* Aumenta altura mínima del v-card */
}

.login-text {
  font-size: 14px;
  color: #009496; /* Ajusta el color del texto */
  text-align: center; /* Centra el texto horizontalmente */
  word-wrap: break-word; /* Asegura que las palabras se ajusten al ancho */
  max-width: 100%; /* Evita que el texto exceda el ancho del contenedor */
  margin: 0 auto; /* Centra el bloque de texto */
  line-height: 1.5; /* Mejora la separación de líneas */
}

.login-text a {
  color: #009496;
  text-decoration: none;
}
.error-message {
  color: red; /* Color del texto de error */
  margin-top: 10px; /* Espaciado superior */
  text-align: center; /* Centrar el texto */
  font-weight: bold; /* Hacer el texto en negrita (opcional) */
  font-size: 14px; /* Tamaño del texto (opcional) */
}
</style>

